import React from "react";
import Split from "../Split";
import { Link } from 'gatsby';
import AboutUs3date from "data/sections/about-us3.json";
import { thumparallax, thumparallaxDown } from "common/thumparallax";

const AboutUs3 = () => {
  React.useEffect(() => {
    setTimeout(() => {
      thumparallax();
      thumparallaxDown();
    }, 1000);
  }, []);

  return (
    <section className="agency section-padding position-re"> 
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="imgsec md-mb50">
              <div className="row">
                <div className="col-md-6">
                  <div className="item">
                    <div
                      className="imgone big-bord wow fadeInDown"
                      data-wow-delay=".8s"
                    >
                      <img
                        className="thumparallax-down"
                        src={AboutUs3date.image1}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <div className="exp">
                      <h2 className="nmb-font">{AboutUs3date.exp.nmb}</h2>
                      <h6>
                        {AboutUs3date.exp.name}</h6>
                      <br />
                    </div>
                    <div className="exp">
                      <h2 className="nmb-font">{AboutUs3date.crr.nmb}</h2>
                      <h6>{AboutUs3date.crr.name}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <div
                      className="imgtwo big-bord wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      <img
                        className="thumparallax"
                        src={AboutUs3date.image2}
                        loading="lazy"
                        alt=""
                      />
                      {/* <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <img
                        className="thumparallax"
                        src={AboutUs3date.image2}
                        alt=""
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 valign">
            <div className="content">
              <Split>
                <h4 className="wow words chars splitting" data-splitting>
                  {AboutUs3date.title.first}
                </h4>
              </Split>
              <Split>
              
              <p className="wow txt words chars splitting" data-splitting>
                  {AboutUs3date.content.first} 
                </p>
              </Split>
              <br />
              <Split>
                <h4 className="wow words chars splitting" data-splitting>
                   {AboutUs3date.title.second}
                </h4>
              </Split>
              <Split>
              
              <p className="wow txt words chars splitting" data-splitting>
                  
                  {AboutUs3date.content.second}
                </p>
              </Split>
              <Link to={`/elf/about-us`} className="butn bord curve mt-40 wow fadeInUp" data-wow-delay=".8s">
                <span>{AboutUs3date.smallTitle}</span>
              </Link>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="line bottom right"></div>
    </section>
  );
};

export default AboutUs3;
