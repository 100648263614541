import React, { useEffect, useCallback } from "react";
// import particlesConfig from "config/particle-config";
// import particlesBlackConfig from "config/pr-s-black";
// import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
// import Split from "../Split";
import IntroWithSlider from "components/Intro-with-slider/intro-with-slider";

const Intro4 = ({ sliderRef, blackStar }) => {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('#particles-js canvas')?.style.removeProperty('position');
    }, 500);
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document.querySelector('#particles-js canvas')?.style.removeProperty('position');
  }, []);

  return (
    // <header ref={sliderRef} className="particles circle-bg valign">
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       <div className="col-lg-12">
    //         {/* <div className="cont text-center">
    //           <h1> <span className="color-font">Elfonze Technologies</span></h1>
    //           <h2 className="test123">
    //             In a world of <span className="color-font">Quantity,</span><br></br>
    //             We offer <span className="color-font">Quality</span>
    //           </h2>
    //           <br/>
    //           <div className="col-lg-12 valign">
    //             <div className="content">
    //               <Split>
    //               <p className="wow txt words chars splitting" data-splitting>
    //                 If we have learned anything from the story of David and Goliath, it’s that the underdog can slay the giant, and at each opportunity ushered to us, our focused approach to each of our customers makes this happen day in and out...
    //               </p>
    //               </Split>
    //             </div>
    //           </div>
    //         </div> */}

           

    //       </div>
         
    //     </div>
    //   </div>
    //   {/* <Particles
    //     id="particles-js"
    //     init={particlesInit}
    //     options={blackStar ? particlesBlackConfig : particlesConfig}
    //   /> */}
    //   {/* <div className="gradient-circle"></div>
    //   <div className="gradient-circle two"></div>
    //   <div className="line bottom left"></div> */}
    // </header>
    <div className="full-width pt-70 custom-slider">
           
                <IntroWithSlider/>
           
    </div>
  );
};

export default Intro4;
