import React from 'react'
import { Link } from 'gatsby'

const CallToAction = ({img}) => {
    return (
      <section
        className="call-action section-padding sub-bg bg-img"
        style={{ backgroundImage: `url(${img ? img : "/img/patrn.svg"})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-9">
            <div className="row justify-content-center">
                <div className="col-lg-12 col-md-10">
                  <div className="sec-head  text-left">
                    <br /><br /><br />
                    <h6 className="wow fadeIn" data-wow-delay=".5s">
                      Let's Start
                    </h6>
                    <h3 className="wow color-font">
                      LET'S DESIGN THE WORLD WE WANT
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3 valign">
              <Link
                to={`/elf/contact-us`}
                className="butn bord curve wow fadeInUp"
                data-wow-delay=".5s"
              >
                <span>Get In Touch</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
}

export default CallToAction