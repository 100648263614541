import React from "react";
import loadingPace from "common/loadingPace";
import appData from "data/app.json";

const LoadingScreen = () => {

  React.useEffect(() => {
    let bodyEl = document.querySelector("body");

    if (appData.showLoading) {
      loadingPace();
      if (bodyEl.classList.contains("hideX")) {
        bodyEl.classList.remove("hideX");
      }
    } else {
      bodyEl.classList.add("hideX");
    }
  }, []);

  /**
   *  style={{
          backgroundImage: 'url("/img/elf-hyd-banner.png")',
          // height: '70%',
          width: '100%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
   */

  return (
    <>
    <div className={`${appData.showLoading === true ? "showX" : "hideX"}`}>
    <div className="loading">
          <span>L</span>
          <span>o</span>
          <span>a</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
          
    </div>
      <div id="preloader"></div>
    </div>
    </>
  );
};

export default LoadingScreen;