const loadingPace = () => {
 
  Pace.on("start", function () {
    document.querySelector("#preloader").classList.remove("isdone");
    document.querySelector(".loading").classList.remove("isdone");
  });
  Pace.on("done", function () {
    document.querySelector("#preloader").classList.add("isdone");
    document.querySelector(".loading").classList.add("isdone");
    
  });

  if (document.querySelector("body").classList.contains("pace-done")) {
    document.querySelector("#preloader").classList.add("isdone");
    document.querySelector(".loading").classList.add("isdone");
  }

  window.addEventListener("load", () => {
    document.querySelector("#preloader").classList.add("isdone");
    document.querySelector(".loading").classList.add("isdone");
    if (document.querySelector('.pace-running.pace-running')) {
      document.querySelector('.pace-running.pace-running').classList.remove('pace-running')
    }
  });
};

export default loadingPace;







// const loadingPace = () => {
//   let timeOutIdx;
//   Pace.on("start", function () {
//     if (document.querySelector("body").classList.contains('pace-done')) {
//       document.querySelector("body").classList.remove('pace-done');
//     }
//     document.querySelector("#preloader").classList.remove("isdone");
//     document.querySelector(".loading").classList.remove("isdone");
//     document.querySelector(".splash-screen").classList.remove("isdone");
//     document.querySelector(".splash-screen").style.display = "block";
//   });
//   // Pace.on("done", function () {
//   //   if (timeOutIdx) return;
//   //   timeOutIdx = setTimeout(() => {

//   //     // document.querySelector(".splash-screen").classList.add("isdone");
//   //     // document.querySelector(".splash-screen").style.display = "none";
//   //   }, 5000);
//   // });

//   Pace.on("hide", () => {
//     setTimeout(() => {
//       document.querySelector("#preloader").classList.add("isdone");
//       document.querySelector(".loading").classList.add("isdone");
//       document.querySelector(".splash-screen").classList.add("isdone");
//       document.querySelector(".splash-screen").style.display = "none";
//     }, 1000);
//   });

//   // if (document.querySelector("body").classList.contains("pace-done")) {
//   //   document.querySelector("#preloader").classList.add("isdone");
//   //   document.querySelector(".loading").classList.add("isdone");
//   //   // document.querySelector(".splash-screen").classList.add("isdone");
//   //   // document.querySelector(".splash-screen").style.display = "none";
//   // }

//   window.addEventListener("load", () => {
//   //   document.querySelector("#preloader").classList.add("isdone");
//   //   document.querySelector(".loading").classList.add("isdone");
//   //   // document.querySelector(".splash-screen").classList.add("isdone");
//   //   // document.querySelector(".splash-screen").style.display = "none";
//     if (document.querySelector('.pace-running.pace-running')) {
//       document.querySelector('.pace-running.pace-running').classList.remove('pace-running');
//     }
//   });
// };

// export default loadingPace;
