import React from "react";
import { Link } from "gatsby";
import introData from "data/sections/intro.json";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation, Pagination, Parallax, Autoplay, EffectCoverflow} from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import removeSlashFromPagination from "common/removeSlashpagination";
import fadeWhenScroll from "common/fadeWhenScroll";
/*,  Parallax , Autoplay */
SwiperCore.use([Navigation, Pagination,Autoplay,EffectCoverflow]);

const IntroWithSlider = ({ sliderRef }) => {
  const [load, setLoad] = React.useState(true);
  React.useEffect(() => {
    fadeWhenScroll(document.querySelectorAll(".fixed-slider .caption"));
    setTimeout(() => {
      removeSlashFromPagination();
      setLoad(false);
    }, 1000);
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  return (
    <header
      ref={sliderRef}
      className="slider slider-prlx fixed-slider text-center position-relative"
    >
      <div className="container">
      <div className="swiper-container parallax-slider">
        {!load ? (
          <Swiper
            speed={1000}
            loop={true}
            autoHeight={true}
            autoplay={{
              delay:5000,
              pauseOnMouseEnter:true,
              disableOnInteraction:false,
            }}
            // parallax={true}
            effect={'coverflow'}
            grabCursor={true}
            modules={[EffectCoverflow]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            pagination={{
              type: "fraction",
              clickable: true,
              el: paginationRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.params.pagination.el = paginationRef.current;
            }}
            onSwiper={(swiper) => {
              console.log("hello")
              setTimeout(() => {
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].childNodes[0].setAttribute(
                    "data-swiper-parallax",
                    0.75 * swiper.width
                  );

                }

                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;

                swiper.params.pagination.el = paginationRef.current;

                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();

                swiper.pagination.destroy();
                swiper.pagination.init();
                swiper.pagination.update();
              });
            }}

            onSlideChange={() => {}}
            className="swiper-wrapper"
            slidesPerView={1}
          >
            {introData.map((slide,index) => (
              <SwiperSlide key={index} className="swiper-slide custom-swiper-slide">
                
                  <div className="container">
                    <div className="row justify-content-center align-items-center homepage-slider">
                      <div className="col-lg-6 col-md-7 col-12">
                        
                        <div className="caption text-left mt-30">
                          
                          <h4 className="color-font observability-title">
                          {
                            slide.logo && 
                            <img src={slide.logo} alt="rakuten" srcset="" className="rakuten-logo"/>
                          }
                          
                            {slide.title}
                          </h4>
                          {slide?.content && <p>{slide.content}</p>}
                          <div className="landingpage-btns">
                          {
                            slide.calltoactions.map((action) => {
                              // return <Link to={action.link} className="butn bord curve wow fadeInUp mt-30" data-wow-delay="0.2s">
                              //           <span>{action.name}</span>
                              //        </Link>
                              return <Link
                              to={action.link}
                              target="_blank"
                              className="butn bord curve wow fadeInUp mt-30 custom-link"
                              data-wow-delay='0.5s'
                            >
                              <span>{action.name}</span>
                            </Link>
                            })
                          }
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-5 col-8">
                        <p><img src={slide.image} alt={slide.title} srcSet="" className="landingpage-illustration" /></p>
                      </div>
                    
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
        <div className="setone setwo">
          <div
            ref={navigationNextRef}
            className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer custom-next-btn"
          >
            <i className="fas fa-chevron-right"></i>
          </div>
          <div
            ref={navigationPrevRef}
            className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer custom-prev-btn"
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        </div>
        <div ref={paginationRef} className="swiper-pagination top botm custom-pagination"></div>

        {/* <div className="social-icon">
          <a href="#0">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#0">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#0">
            <i className="fab fa-behance"></i>
          </a>
          <a href="#0">
            <i className="fab fa-pinterest-p"></i>
          </a>
        </div> */}
      </div>
      </div>
    </header>
  );
};

export default IntroWithSlider;
